import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import BonusTabularData from './BonusTabularData';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useQueryClient, useQuery } from 'react-query';
import { BONUS_STATUS } from '../constants';
import SubscriptionCancelledModal from 'components/Modals/SubscriptionCancelledModal';
import { HISTORY_FROM } from 'constants.js';
import { GET_BONUS_LIST, GET_BONUS_COUNT } from 'Services/apiKeys';
import { useToaster } from 'Context/SnackbarContext';
import { useGetBonusList } from '../customAPIHooks';
import { rowFormatterForCompleted, rowFormatterForScheduled, threeDotMenuOptions } from '../componentUtils';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import queryString from 'query-string';
import DeleteModal from 'components/ReusableComponents/ConfirmModal';
import { deleteBonus, getBonusCount } from '../Services/APIFunctions';

const tabHandler = (locationData) => {
    if (typeof locationData.tab === 'string') {
        switch (locationData.tab) {
            case BONUS_STATUS.SCHEDULED:
            case BONUS_STATUS.COMPLETED:
                return locationData.tab;
            default:
                return BONUS_STATUS.SCHEDULED;
        }
    } else {
        return BONUS_STATUS.SCHEDULED;
    }
};

const Index = ({ history }) => {
    const location = useLocation();
    const selectedBonus = useRef();
    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();
    const [sort, setSort] = useState({ key: null, direction: null });
    const [count, setCount] = useState({});
    const [search, setSearch] = useState('');
    const locationData = queryString.parse(location.search);
    const { subscription } = useSelector(mapStateToProps, shallowEqual);
    const [btnLoading, setBtnLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [total, setTotal] = useState(0);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [activeKey, setActiveKey] = useState(tabHandler(locationData));

    const optionMenuScheduled = [threeDotMenuOptions.edit(), threeDotMenuOptions.delete(setOpenDeleteModal)];
    const onClose = () => setOpenDeleteModal(false);

    const onDeleteHandler = async () => {
        try {
            setBtnLoading(true);
            await deleteBonus(selectedBonus.current._id);
            queryClient.invalidateQueries(GET_BONUS_LIST);
            // Update the count based on the status before deletion
            if (selectedBonus.current.status === BONUS_STATUS.SCHEDULED) {
                setCount((prevCount) => ({
                    ...prevCount,
                    scheduled: prevCount.scheduled - 1,
                }));
            } else if (selectedBonus.current.status === BONUS_STATUS.COMPLETED) {
                setCount((prevCount) => ({
                    ...prevCount,
                    completed: prevCount.completed - 1,
                }));
            }
            setBtnLoading(false);
            showSnackBarMessage(SetSnackbar, 'success', 'Bonus deleted');
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err.message);
        } finally {
            onClose();
        }
    };
    const modalData = {
        confirm: 'Delete',
        cancel: 'Cancel',
        heading: `Are you sure you want to delete ${selectedBonus.current?.name} bonus?`,
        subHeading: `Once the scheduled bonus is deleted, you can’t recover it.`,
    };
    const onSuccess = (data) => {
        setTotal(data.totalCount);
        if (activeKey === BONUS_STATUS.SCHEDULED) {
            setTableData(
                data.data?.map(
                    rowFormatterForScheduled(
                        optionMenuScheduled,
                        selectedBonus,
                        history,
                        subscription,
                        setSubscriptionCancelled
                    )
                )
            );
        } else {
            setTableData(data.data?.map(rowFormatterForCompleted(history)));
        }
    };
    const onError = (err) => showSnackBarMessage(SetSnackbar, 'error', err.message);

    const { isLoading, isFetching } = useGetBonusList({
        onSuccess,
        onError,
        queryKey: GET_BONUS_LIST,
        queryParams: {
            limit: 10,
            sort,
            search,
            pageNumber,
            status: activeKey,
        },
    });

    useQuery([GET_BONUS_COUNT], getBonusCount, {
        onSuccess: (data) => setCount(data),
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
    });

    const handleSearchChange = debounce((value) => {
        setSearch(value);
        setPageNumber(1);
    }, 400);

    const bonusTableProps = {
        history,
        pageNumber,
        setPageNumber,
        setActiveKey,
        activeKey,
        tableData,
        loading: isLoading || isFetching,
        count: count,
        sort,
        setSort,
        total,
        handleSearchChange,
    };

    return (
        <>
            <BonusTabularData {...bonusTableProps} />
            {openDeleteModal && (
                <DeleteModal
                    red={true}
                    loading={btnLoading}
                    open={openDeleteModal}
                    data={modalData}
                    onClose={onClose}
                    onConfirm={onDeleteHandler}
                />
            )}
            <SubscriptionCancelledModal
                open={subscriptionCancelled}
                onClose={() => setSubscriptionCancelled(false)}
                history={history}
                currentRoute={HISTORY_FROM.EMPLOYEE_AWARDS}
            />
        </>
    );
};
const mapStateToProps = ({ Payments }) => ({
    subscription: Payments?.subscription,
});
Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
