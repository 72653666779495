import React from 'react';
import RecognizedUsers from 'components/Dashboard/Content/Recognitions/RecognitionsHome/RecognitionFeed/RecognitionItem/RecognizedUsers';
import RecognitionMessage from 'components/Dashboard/Content/Recognitions/RecognitionsHome/RecognitionFeed/RecognitionItem/RecognitionMessage';
import EWButton from 'components/ReusableComponents/EWButton';
import ImageStack from 'components/ReusableComponents/ImageStack';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';

const Index = ({
    addOnRecognitions,
    getUsersWithAddOnRecognitions,
    recognitionData,
    handleGiveAddOnRecognition,
    setViewAddOnRecognitionsModal,
}) => {
    const addOnRecognitionsUsers = addOnRecognitions.map((addOnRecognition) => addOnRecognition.sender);
    return (
        <>
            <RecognizedUsers users={recognitionData?.receivers} value={recognitionData?.recognition.companyValues} />
            <RecognitionMessage>
                <RecognitionMessage.Title
                    sender={recognitionData.sender}
                    channel={recognitionData.channel}
                    messageLink={recognitionData.recognition.messageLink}
                />
                <RecognitionMessage.Content message={recognitionData.recognition.message} showAddOnValue />
                <RecognitionMessage.Image gifLink={recognitionData.recognition.gifLink} />
                <RecognitionMessage.AddOnButton
                    handleGiveAddOnRecognition={handleGiveAddOnRecognition}
                    recognitionData={recognitionData}
                />
                <RecognitionMessage.Divider
                    addOnRecognitionsCount={recognitionData.recognition.addOnRecognitions.length}
                />
            </RecognitionMessage>
            {addOnRecognitions?.length > 0 && (
                <EWButton plainTextButton primary={false} onClick={() => setViewAddOnRecognitionsModal(true)}>
                    <div className='recognition-message-add-ons'>
                        <ImageStack members={addOnRecognitionsUsers} width={21} height={21} />
                        <p>
                            {`${getUsersWithAddOnRecognitions(addOnRecognitionsUsers)}`} <ArrowRightIcon />
                        </p>
                    </div>
                </EWButton>
            )}
        </>
    );
};

Index.propTypes = {
    addOnRecognitions: PropTypes.object,
    getUsersWithAddOnRecognitions: PropTypes.func,
    recognitionData: PropTypes.object,
    handleGiveAddOnRecognition: PropTypes.func,
    setViewAddOnRecognitionsModal: PropTypes.func,
};

export default Index;
