import React from 'react';
import { format } from 'date-fns';
import { default as Tooltip } from 'components/Styles/Tooltip';
import MemberTag from 'components/Dashboard/Content/Bonus/Components/MemberTag';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import { ReactComponent as MoreDetailsIcon } from 'Assets/images/more-details.svg';
import AvatarGroupPopup from 'components/ReusableComponents/AvatarGroupPopup';
import { HISTORY_FROM } from 'constants.js';

export const rowFormatter = (history) => (award) => ({
    id: award._id,
    row: [
        <strong key={award._id}>
            <EllipsisText text={award.name} />
        </strong>,
        award.launchDate ? format(new Date(award.launchDate), 'MMM dd, yyyy') : '-',
        <div key={award._id}>
            <MemberTag history={history} memberID={award.awardee[0]._id} memberName={award.awardee[0].userName} />
            {award.awardee.length > 1 && <span style={{ marginLeft: '5px', verticalAlign: 'middle' }}>&&nbsp;</span>}
            {award.awardee.length === 2 && (
                <MemberTag history={history} memberID={award.awardee[1]._id} memberName={award.awardee[1].userName} />
            )}
            {award.awardee.length > 2 && (
                <AvatarGroupPopup
                    assignedMembers={award.awardee.slice(1)}
                    moreUsers={true}
                    customHeight={'0px'}
                    marginLeft='-8px'
                    history={history}
                    fromLocation={HISTORY_FROM.BONUS}
                />
            )}
        </div>,
        <MemberTag
            key={award._id}
            history={history}
            memberID={award.awardedBy._id}
            memberPhoto={award.awardedBy.pictureURL}
            memberName={award.awardedBy.userName}
        />,

        award.defaultAmount ? `${award.defaultCurrency} ${award.defaultAmount}` : 'N/A',
        <Tooltip title={award?.message} key={award._id} white>
            <MoreDetailsIcon />
        </Tooltip>,
    ],
});

export const getSortHandler = (sort, setSort, tableProperties) => (index) =>
    setSort({ key: tableProperties.SORT_KEY[index], direction: -(sort.direction || -1) });
