import React from 'react';
import PropTypes from 'prop-types';
import ImageStack from 'components/ReusableComponents/ImageStack';
import './style.scss';
import { format, isValid } from 'date-fns';
import { getCountries } from 'utils/countryUtils';
import { getIntegratedChannelName } from 'utils/HelperFunctions';

const Index = ({ bonusDetails, step, platform, workspaceCountry }) => {
    const countries = getCountries(true);
    const defaultCountry = countries.filter((item) => item.countryCode === workspaceCountry);
    const { bonusName, awardee, bonusAmount, bonusCurrency, announcement } = bonusDetails;
    const selectedAwardee = {
        count: awardee.length,
        userImages: awardee.map((user) => user.pictureURL),
    };

    const scheduledDate =
        isValid(announcement.date) && `${announcement.time}, ` + `${format(announcement.date, 'MMMM dd, yyyy')}`;

    return (
        <div className='bonus-creation-info-container'>
            <div>
                <h4 className='header-4 title'>{`${bonusName || 'NameofBonus'}`}</h4>
            </div>

            <div>
                <h4 className='sub-header title'>Bonus Awardee</h4>
                <div className='value-section'>
                    {selectedAwardee.count ? (
                        <>
                            <ImageStack members={selectedAwardee.userImages} />
                            <p className='bold'>{selectedAwardee.count}</p>
                        </>
                    ) : (
                        '-'
                    )}
                </div>
            </div>

            <div>
                <div className='summary-separator' />
                <h4 className='sub-header'>Bonus Prize</h4>
                <div className='bonus-prize'>
                    {bonusAmount ? (
                        <>
                            <img src={defaultCountry?.[0].countryFlag} alt='' width={'24px'} height={'16px'} />
                            <p className='bold'>{bonusCurrency}</p>
                            <p className='bold'>{bonusAmount}</p>
                        </>
                    ) : (
                        '-'
                    )}
                </div>
            </div>

            {step === 1 ? (
                <>
                    <div>
                        <div className='summary-separator' />
                        <h4 className='sub-header'>Scheduled at</h4>
                        <div className='value-section'>
                            <p className='bold'>{scheduledDate}</p>
                        </div>
                    </div>

                    {announcement.channelName ? (
                        <div>
                            <div className='summary-separator' />
                            <h4 className='sub-header'>Announce at</h4>
                            <div className='value-section'>
                                <p className='bold'>{getIntegratedChannelName(announcement, platform)}</p>
                            </div>
                        </div>
                    ) : null}
                </>
            ) : null}
        </div>
    );
};

Index.propTypes = {
    bonusDetails: PropTypes.object,
    step: PropTypes.number,
    platform: PropTypes.string,
    workspaceCountry: PropTypes.string,
};

export default Index;
