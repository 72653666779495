import React from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import RecognitionHeader from 'components/Dashboard/Content/Recognitions/RecognitionsHome/RecognitionFeed/RecognitionItem/RecognitionHeader';
import RecognitionPost from './RecognitionPost';
import CelebrationPost from './CelebrationPost';
import AwardPost from './AwardPost';
import { POST_TYPE } from '../constants';

const Index = ({ recognitionData, handleGiveAddOnRecognition, setViewAddOnRecognitionsModal }) => {
    // if same user gives multiple add on recognitions then show user's name and pic only once
    const addOnRecognitions = uniqBy(recognitionData?.recognition?.addOnRecognitions, 'senderObjectId');

    const getUsersWithAddOnRecognitions = (addOnRecognitions) => {
        const userNames = addOnRecognitions.map((addOnRecognition) => addOnRecognition.userName);
        let names = '';
        if (userNames.length === 1) {
            names = userNames[0];
        } else if (userNames.length === 2) {
            names = userNames.join(' and ');
        } else if (userNames.length > 2) {
            names = `${userNames.slice(0, 2).join(',')}  and ${userNames.length - 2} more`;
        }
        return `${names} gave Add on ${userNames.length === 1 ? 'recognition' : 'recognitions'} `;
    };

    return (
        <>
            <RecognitionHeader recognitionData={recognitionData} />
            {(recognitionData?.type === POST_TYPE.CELEBRATION || recognitionData?.type === POST_TYPE.BONUS) && (
                <CelebrationPost recognitionData={recognitionData} />
            )}

            {recognitionData?.type === POST_TYPE.AWARD && <AwardPost recognitionData={recognitionData} />}

            {recognitionData.type === POST_TYPE.RECOGNITION && (
                <RecognitionPost
                    addOnRecognitions={addOnRecognitions}
                    getUsersWithAddOnRecognitions={getUsersWithAddOnRecognitions}
                    recognitionData={recognitionData}
                    handleGiveAddOnRecognition={handleGiveAddOnRecognition}
                    setViewAddOnRecognitionsModal={setViewAddOnRecognitionsModal}
                />
            )}
        </>
    );
};

Index.propTypes = {
    recognitionData: PropTypes.object,
    handleGiveAddOnRecognition: PropTypes.func,
    setViewAddOnRecognitionsModal: PropTypes.func,
};

export default Index;
