import { ROUTES } from 'constants.js';

export const QUICK_ACTIONS = [
    {
        label: 'Launch Pulse',
        route: ROUTES.PULSE_HOME,
    },
    {
        label: 'Send/Request Feedback',
        route: ROUTES.FEEDBACK_HOME,
    },
    {
        label: 'Launch Award',
        route: ROUTES.EMPLOYEE_AWARDS_LAUNCH,
    },
    {
        label: 'Send Bonus',
        route: ROUTES.BONUS,
    },
    {
        label: 'Suggestion Box',
        route: ROUTES.SUGGESTION_BOX_HOME,
    },
];
