import React from 'react';
import PropTypes from 'prop-types';
import ActionMenuItem from './ActionMenuItem';
import { QUICK_ACTIONS } from './constants';
import './styles.scss';

const Index = ({ history }) => {
    return (
        <div className='action-menu-container'>
            <p className='action-menu-text'>Other Actions</p>
            <div className='action-menu-items'>
                {QUICK_ACTIONS.map(({ label, route }) => (
                    <ActionMenuItem key={label} label={label} route={route} history={history} />
                ))}
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
