import { useMutation } from 'react-query';
import { launchBonus, editLaunchedBonus } from '../Services/APIFunctions';
import { reduce } from 'lodash';
import { showSnackBarMessage } from 'utils/HelperFunctions';

export const useLaunchBonus = ({ onError, onSuccess, editBonusId }) => {
    return useMutation(editBonusId ? editLaunchedBonus : launchBonus, {
        onError: async (err) => onError(err),
        onSuccess: async () => onSuccess(),
    });
};

export const validateBonusDetails = ({ bonusDetails, SetSnackbar, editBonusId }) => {
    const { bonusName, awardee, message, defaultAmount, companyValues } = bonusDetails;

    if (bonusName === '' || awardee.length === 0 || message === '' || defaultAmount === '' || companyValues === '') {
        showSnackBarMessage(SetSnackbar, 'error', 'Required fields are missing');
        return false;
    }
    return true;
};

export const isValidBonus = ({ bonusDetails, SetSnackbar, setStep }) => {
    let valid = true;
    const { bonusName, awardee, message, defaultAmount, companyValues, announcement } = bonusDetails;

    if (bonusName === '' || awardee.length === 0 || message === '' || defaultAmount === '' || companyValues === '') {
        valid = false;
    }
    if (!valid) {
        setStep(0);
    }
    if (!announcement.date || !announcement.time || !announcement.channelId || !announcement.channelName) {
        showSnackBarMessage(SetSnackbar, 'error', 'Please enter all the announcement details');
        return false;
    }

    return valid;
};

export const getRequestedListData = (users) =>
    reduce(
        users,
        ([channelData, userData], item) => {
            if (item.channel) {
                channelData.push({
                    channelId: item.channelID,
                    channelName: item.channelName,
                });
            } else {
                userData.push(item.id);
            }
            return [channelData, userData];
        },
        [[], []]
    );

export const getDefaultCountry = (workspaceCountry, countries) => {
    const filteredCountry = countries.filter((item) => item.countryCode === workspaceCountry);
    return {
        amount: '',
        currency: filteredCountry[0].currency,
        country: filteredCountry[0].country,
    };
};
