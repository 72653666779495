import React from 'react';
import PropTypes from 'prop-types';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import { makePlural } from 'utils/HelperFunctions';
import { CELEBRATION_TYPE, POST_TYPE } from 'components/Dashboard/Content/Home/constants';
import './style.scss';

const Index = ({ recognitionData, showDownArrowIcon = true, isAddOn = false }) => {
    const { sender, receivers, type, celebrationType, employeeAward, recognition, rewardName } = isAddOn
        ? { ...recognitionData, recognition: recognitionData }
        : recognitionData;

    const getHeaderImage = () => {
        if (type === POST_TYPE.CELEBRATION || type === POST_TYPE.BONUS || type === POST_TYPE.AWARD) {
            return receivers[0].pictureURL ?? require('Assets/images/defaultUser.png');
        }

        return sender.pictureURL ?? require('Assets/images/defaultUser.png');
    };

    const getHeaderText = () => {
        switch (type) {
            case POST_TYPE.RECOGNITION:
                return (
                    <span className='header-text'>
                        <strong className='mr-2'>
                            <EllipsisText text={sender.userName} white />
                        </strong>{' '}
                        gave&nbsp;<strong>{recognition?.rewardName ?? rewardName}</strong>&nbsp;to{' '}
                        {showDownArrowIcon && !isAddOn ? '⬇️' : ''}
                    </span>
                );

            case POST_TYPE.CELEBRATION:
                return (
                    <span className='header-text'>
                        <strong>
                            <EllipsisText text={receivers[0].userName} white />
                        </strong>
                        &nbsp;
                        {receivers?.length > 1
                            ? `and ${makePlural(receivers.length - 1, 'other')} are celebrating their`
                            : 'is celebrating their'}
                        &nbsp;
                        <span style={{ fontWeight: 500 }}>
                            {celebrationType === CELEBRATION_TYPE.BIRTHDAY
                                ? CELEBRATION_TYPE.BIRTHDAY
                                : 'work anniversary'}
                        </span>
                    </span>
                );

            case POST_TYPE.BONUS:
                return (
                    <span className='header-text'>
                        <strong>
                            <EllipsisText text={receivers[0].userName} white />
                        </strong>{' '}
                        got a bonus
                    </span>
                );

            case POST_TYPE.AWARD:
                return (
                    <span className='header-text'>
                        <strong>
                            <EllipsisText text={receivers[0]?.userName} white />
                        </strong>
                        &nbsp;
                        {receivers?.length > 1 && `and ${makePlural(receivers?.length - 1, 'other')}`}
                        received&nbsp;
                        <strong>
                            "<EllipsisText text={employeeAward.awardName} />"
                        </strong>{' '}
                        award
                    </span>
                );

            default:
                return null;
        }
    };

    return (
        <div className='recognition-header-user'>
            <img width={22} height={22} className='user-image' src={getHeaderImage()} alt='' />
            {getHeaderText()}
        </div>
    );
};

Index.propTypes = {
    recognitionData: PropTypes.shape({
        sender: PropTypes.shape({
            userName: PropTypes.string,
            pictureURL: PropTypes.string,
        }),
        receivers: PropTypes.arrayOf(
            PropTypes.shape({
                userName: PropTypes.string,
                pictureURL: PropTypes.string,
            })
        ),
        type: PropTypes.string,
        celebrationType: PropTypes.string,
        employeeAward: PropTypes.shape({
            awardName: PropTypes.string,
        }),
        recognition: PropTypes.shape({
            rewardName: PropTypes.string,
        }),
        rewardName: PropTypes.string,
        message: PropTypes.string,
    }).isRequired,
    showDownArrowIcon: PropTypes.bool,
    isAddOn: PropTypes.bool,
};

export default Index;
