import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import Leaderboard from '../Leaderboard';
import UserRoute from '../Users';
import EnpsComponent from '../ENPS';
import Analytics from '../AnalyticsV2';
import RedeemPoints from '../RedeemPoints';
import GlobalAdminSettings from '../GlobalAdminSettings';
import RedemptionConfiguration from '../RedemptionConfigurations';
import Profile from '../Profile';
import Catalog from '../Catalog';
import PaymentsV2 from '../PaymentsV2';
import Celebration from '../Celebration';
import Feedback from '../Feedback';
import MyFeedback from '../Feedback/MyFeedback';
import SuggestionBox from '../SuggestionBox';
import Bonus from '../Bonus';
import AllSuggestion from '../SuggestionBox/AllSuggestions';
import Tasks from '../Recognitions/Tasks';
import RecognitionsConfigurations from '../Recognitions/Configuration';
import Activities from '../Activities';
import Notifications from '../Notifications';
import Home from '../Home';
import { useCommonContext } from 'Context/CommonContext';
import { PLATFORM } from 'constants.js';
const PulseSurvey = lazy(() => import('../PulseSurvey'));
const EmployeeAwards = lazy(() => import('../EmployeeAwards'));

const msteamsOpenRoutes = [
    {
        path: '/tasks',
        render: (props) => <Tasks {...props} />,
    },
    {
        path: '/activities',
        render: (props) => <Activities {...props} />,
    },
    {
        path: '/analytics/leaderboard',
        render: (props) => <Leaderboard {...props} />,
    },
    {
        path: '/redemptions/redeem',
        render: (props) => <RedeemPoints {...props} />,
    },
    {
        path: '/profile/:id',
        render: (props) => <Profile {...props} />,
    },
];

const msteamsAdminRoutes = [
    {
        path: '/users',
        render: (props) => <UserRoute {...props} />,
    },
    {
        path: '/global-settings',
        render: (props) => <GlobalAdminSettings {...props} />,
    },
    {
        path: '/payments',
        render: (props) => <PaymentsV2 {...props} />,
    },
    {
        path: '/redemptions/catalog',
        render: (props) => <Catalog {...props} />,
    },
    {
        path: '/redemptions/configuration',
        render: (props) => <RedemptionConfiguration {...props} />,
    },
    {
        path: '/recognitions/configuration',
        render: (props) => <RecognitionsConfigurations {...props} />,
    },
    {
        path: '/tasks',
        render: (props) => <Tasks {...props} />,
    },
    {
        path: '/celebration',
        render: (props) => <Celebration {...props} />,
    },
    {
        path: '/employee-awards',
        render: (props) => <EmployeeAwards {...props} />,
    },
];

const openRouteList = ({ isPulseEnabled, platform }) => {
    const isMsteams = platform === 'MSTeams';
    const openRoutes = [
        {
            path: '/home',
            render: (props) => <Home {...props} />,
        },
        isPulseEnabled !== false && {
            path: '/pulse',
            render: (props) => <PulseSurvey {...props} />,
        },
        platform === PLATFORM.WEB && {
            path: '/notifications',
            render: (props) => <Notifications {...props} />,
        },
        {
            path: '/feedback/my-feedback',
            render: (props) => <MyFeedback {...props} />,
        },
        {
            path: '/suggestion-box/all-suggestions',
            render: (props) => <AllSuggestion {...props} />,
        },
        {
            path: '/analytics',
            render: (props) => <Analytics {...props} />,
        },
    ].filter(Boolean);
    if (isMsteams) {
        return msteamsOpenRoutes;
    } else {
        return [...msteamsOpenRoutes, ...openRoutes];
    }
};

const adminRouteList = (platform) => {
    const isMsteams = platform === 'MSTeams';
    const adminRoutes = [
        {
            path: '/bonus',
            render: (props) => <Bonus {...props} />,
        },
        {
            path: '/eNPS',
            render: (props) => <EnpsComponent {...props} />,
        },
        {
            path: '/feedback',
            render: (props) => <Feedback {...props} />,
        },
        {
            path: '/suggestion-box',
            render: (props) => <SuggestionBox {...props} />,
        },
    ];
    if (isMsteams) {
        return msteamsAdminRoutes;
    } else {
        return [...msteamsAdminRoutes, ...adminRoutes];
    }
};

const Index = ({ setContent }) => {
    const { isPulseEnabled = true } = useCommonContext();
    const { isAdmin, platform, config } = useSelector(mapStateToProps, shallowEqual);
    const BASE_PATH = '/dashboard';
    const mapToRoutes = ({ path, render }) => (
        <Route key={path} path={BASE_PATH + path} render={(props) => render({ ...props, setContent })} />
    );
    return (
        <div style={{ overflow: 'auto' }} className='dashboard-content-scroll'>
            <Suspense fallback={<CustomLoader />}>
                <Switch>
                    {[...openRouteList({ isPulseEnabled, platform }), ...(isAdmin ? adminRouteList(platform) : [])].map(
                        mapToRoutes
                    )}
                    {config && <Redirect to='/dashboard/redemptions/redeem' />}
                </Switch>
            </Suspense>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAdmin: state.User.isAdmin,
    platform: state.Workspace.platform,
    config: state.Workspace.config,
});

Index.propTypes = {
    setContent: PropTypes.func,
};

export default Index;
