import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import { HISTORY_FROM } from 'constants.js';
import EllipsisText from 'components/ReusableComponents/EllipsisText';

export const MemberTag = ({ history, memberID, memberName }) => {
    return (
        <button
            className='link-btn'
            onClick={() =>
                memberID
                    ? handlePageRedirectionWithinSubMenu({
                          history,
                          redirectionURL: `/dashboard/profile/${memberID}`,
                          navMenuCode: HISTORY_FROM.BONUS,
                      })()
                    : null
            }
        >
            <EllipsisText text={memberName} white customClassName='mb-0 bonus-awardee-name' maxWidth='200px' />
        </button>
    );
};

MemberTag.propTypes = {
    history: PropTypes.object,
    memberID: PropTypes.string,
    memberName: PropTypes.string,
};

export default MemberTag;
